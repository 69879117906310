export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";

export const SUBMIT_PROJECT = "SUBMIT_PROJECT";
export const SUBMIT_PROJECT_SUCCESS = "SUBMIT_PROJECT_SUCCESS";
export const SUBMIT_PROJECT_FAILURE = "SUBMIT_PROJECT_FAILURE";

export const FETCH_EDIT_PROJECT = "FETCH_EDIT_PROJECT";
export const FETCH_EDIT_PROJECT_SUCCESS = "FETCH_EDIT_PROJECT_SUCCESS";
export const FETCH_EDIT_PROJECT_FAILURE = "FETCH_EDIT_PROJECT_FAILURE";

export const EDIT_PROJECT = "EDIT_PROJECT";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_FAILURE = "EDIT_PROJECT_FAILURE";

export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
