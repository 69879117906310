export const SELECT_VALUE = "SELECT_VALUE";
export const TOP_THREE_VALUE = "TOP_THREE_VALUE";
export const OTHER_VALUE = "OTHER_VALUE";
export const ADD_OTHER = "ADD_OTHER";

export const SUBMIT_VALUE = "SUBMIT_VALUE";
export const SUBMIT_VALUE_SUCCESS = "SUBMIT_VALUE_SUCCESS";
export const SUBMIT_VALUE_FAILURE = "SUBMIT_VALUE_FAILURE";

export const FETCH_VALUES = "FETCH_VALUES";
export const FETCH_VALUES_SUCCESS = "FETCH_VALUES_SUCCESS";
export const FETCH_VALUES_FAILURE = "FETCH_VALUES_FAILURE";

export const DELETE_VALUE = "DELETE_VALUE";
export const DELETE_VALUE_SUCCESS = "DELETE_VALUE_SUCCESS";
export const DELETE_VALUE_FAILURE = "DELETE_VALUE_FAILURE";
